<template>
  <div class="pb__wrapper">
    <div class="pb__container">
      <span
        :class="getMilestoneCSSClass(m, index)"
        v-for="(m, index) in milestones"
        :key="index"
      >
        <span class="pb__milestone__shape" v-if="m.type != 'major'">
          <img
            src="@/assets/images/vle/unit-complete.svg"
            alt="check"
            v-if="m.isCompleted && m.type === 'minor'"
          /> </span
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    milestones: {
      type: Array,
      default() {
        // added for testing purpose
        return [
          {
            type: "major",
            isLocked: false,
            isCompleted: true
          },
          {
            type: "minor",
            isLocked: false,
            isCompleted: true
          },
          {
            type: "assessment",
            isLocked: false,
            isCompleted: true
          },
          {
            type: "major",
            isLocked: false,
            isCompleted: false
          },
          {
            type: "minor",
            isLocked: false,
            isCompleted: false
          },
          {
            type: "assessment",
            isLocked: false,
            isCompleted: false
          },
          {
            type: "major",
            isLocked: true,
            isCompleted: false
          },
          {
            type: "minor",
            isLocked: true,
            isCompleted: false
          },
          {
            type: "assessment",
            isLocked: true,
            isCompleted: false
          },
          {
            type: "major",
            isLocked: false,
            isCompleted: true
          },
          {
            type: "minor",
            isLocked: false,
            isCompleted: true
          },
          {
            type: "assessment",
            isLocked: false,
            isCompleted: true
          },
          {
            type: "major",
            isLocked: false,
            isCompleted: false
          },
          {
            type: "minor",
            isLocked: false,
            isCompleted: false
          },
          {
            type: "assessment",
            isLocked: false,
            isCompleted: false
          },
          {
            type: "major",
            isLocked: true,
            isCompleted: false
          },
          {
            type: "minor",
            isLocked: true,
            isCompleted: false
          },
          {
            type: "assessment",
            isLocked: true,
            isCompleted: false
          }
        ];
      }
    }
  },
  methods: {
    getMilestoneCSSClass(milestone, index) {
      let cssClass = "pb__milestone";

      // type of shape
      if (milestone.type === "major") {
        cssClass += " pb__milestone--major";
      } else if (milestone.type === "minor") {
        cssClass += " pb__milestone--minor";
        if (milestone.isCompleted === false) {
          cssClass += " pb__milestone--incomplete";
        }
      } else if (milestone.type === "assessment") {
        cssClass += " pb__milestone--assessment";
      }

      // status flags
      if (milestone.isLocked === true) {
        cssClass += " pb__milestone--locked";
      } else if (milestone.isCompleted === true) {
        cssClass += " pb__milestone--completed";
      }
      // check color update
      if (
        index === 0 &&
        this.milestones.length > 1 &&
        this.milestones[index + 1].isCompleted === true
      ) {
        cssClass += " pb__milestone--major-primary";
      }

      // check if milestone length <= 2
      if (this.milestones.length === 2) {
        cssClass += " pb__milestone--major-single";
      }

      return cssClass;
    }
  }
};
</script>
<style lang="scss" scoped>
// colors
$locked-bg: #eaeaea;
$locked-fg: #a0a4a8;
$default-color: #c5deff;
$assessment-active-color: #1debce;

// sizes
$progress-bar-size: 9px;
$major-milestone-size: 15px;
$minor-milestone-size: 17px;

.pb__wrapper {
  height: 100%;
  .pb__container {
    height: calc(100% - 23px);
    width: $progress-bar-size;
    border-radius: 5px;
    background: $default-color;

    .pb__milestone {
      display: block;
      position: relative;
      top: 0;
      height: 62px;

      &:first-child {
        border-top-left-radius: $progress-bar-size;
        border-top-right-radius: $progress-bar-size;
        height: 34px;
      }

      &:last-child {
        border-bottom-left-radius: $progress-bar-size;
        border-bottom-right-radius: $progress-bar-size;
        height: 0;
      }

      /* milestone shapes */
      .pb__milestone__shape {
        display: block;
        position: absolute;
      }
      &.pb__milestone--major {
        .pb__milestone__shape {
          width: $major-milestone-size;
          border-radius: $major-milestone-size;
          background: $brand-primary-lighter;
          height: $major-milestone-size;
          border: 3px solid #ffffff;
          left: 50%;
          margin-left: -calc($major-milestone-size/2);
          box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
        }
        &.pb__milestone--major-primary {
          background: $brand-primary-lighter;
        }
      }
      &.pb__milestone--major-single {
        &:first-child {
          height: 44px;
        }
      }
      &.pb__milestone--minor {
        &.pb__milestone--incomplete {
          .pb__milestone__shape {
            width: $major-milestone-size;
            border-radius: $major-milestone-size;
            height: $major-milestone-size;
            background: #fff;
            border: 2px solid $brand-primary-lighter;
          }
        }
        .pb__milestone__shape {
          width: $minor-milestone-size;
          border-radius: $minor-milestone-size;
          background: $brand-primary-lighter;
          height: $minor-milestone-size;
          left: -3px;
          position: relative;
          img {
            position: absolute;
            top: 2px;
            left: 1px;
          }
        }
      }
      &.pb__milestone--assessment {
        .pb__milestone__shape {
          width: 30px;
          height: 30px;
          background: url("~@/assets/images/icons/active-assessment.svg")
            no-repeat;
          left: -12px;
          top: 12px;
        }
      }

      /* different states */
      &.pb__milestone--locked {
        background: $locked-bg;

        &.pb__milestone--major {
          .pb__milestone__shape {
            background: $locked-fg;
          }
        }

        &.pb__milestone--minor {
          .pb__milestone__shape {
            background: url("~@/assets/images/icons/lock-grey.svg") no-repeat;
            border: none;
            opacity: 1;
            background-color: #fff;
          }
        }

        &.pb__milestone--assessment {
          .pb__milestone__shape {
            background-image: url("~@/assets/images/icons/inactive-assessment.svg");
          }
        }
      }

      &.pb__milestone--completed {
        background: $brand-primary-lighter;

        &.pb__milestone--major {
          .pb__milestone__shape {
            background: $brand-primary-lighter;
          }
        }

        &.pb__milestone--minor {
          .pb__milestone__shape {
            background: #ffffff;
            opacity: 1;
          }
        }

        &.pb__milestone--assessment {
          .pb__milestone__shape {
            border-bottom-color: $assessment-active-color;
          }
        }
      }
    }
  }
}
[dir="rtl"] {
  .pb__wrapper {
    .pb__container {
      .pb__milestone {
        .pb__milestone__shape {
          left: 3px;
          img {
            right: 1px;
          }
        }
      }
    }
  }
}
</style>
